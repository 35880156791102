import React, { useState, useEffect, useRef } from 'react';
import { css, keyframes } from 'emotion';
import { useTheme } from 'emotion-theming';

const demoSlide = keyframes`
  0% {
    transform: translate3d(0,0,0);
  }

  100% {
    transform: translate3d(400%,0,0);
  }
`;

function Point({ onChange, size, curve, children }) {
  const [dragging, setDragging] = useState(false);
  const initialCoordinates = useRef(null);
  const initalCurve = useRef(null);

  useEffect(() => {
    if (!dragging) {
      return;
    }
    function handleMouseUp() {
      setDragging(false);
    }
    function handleMouseMove({ clientX, clientY }) {
      const { clientX: initX, clientY: initY } = initialCoordinates.current;
      let newX = initalCurve.current[0] + (clientX - initX) / size;
      newX = newX >= 1 ? 1 : newX;
      newX = newX <= 0 ? 0 : newX;
      onChange([newX, initalCurve.current[1] + (initY - clientY) / size]);
    }
    document.addEventListener('mouseup', handleMouseUp);
    document.addEventListener('mousemove', handleMouseMove);
    return () => {
      document.removeEventListener('mouseup', handleMouseUp);
      document.removeEventListener('mousemove', handleMouseMove);
    };
  }, [dragging, onChange, size]);

  function handleMouseDown({ clientX, clientY }) {
    initialCoordinates.current = {
      clientX,
      clientY,
    };
    initalCurve.current = curve;
    setDragging(true);
  }

  return (
    <g className="handle" onMouseDown={handleMouseDown}>
      {children}
    </g>
  );
}

const EasingCurve = ({ curve, setCurve }) => {
  const theme = useTheme();
  const size = 300;
  const padHorz = 30;
  const padVert = 400;
  const c = 10;
  const { p1x, p1y, p2x, p2y } = curve;

  return (
    <div
      className={css`
        svg {
          .gridLine {
            stroke: ${theme.color.secondaryBorder};
            stroke-width: 2px;
            opacity: 0.5;
            stroke-dasharray: 4px;
            stroke-dashoffset: 8px;
            stroke-linecap: round;
          }
          .lineToPoint {
            stroke: ${theme.color.secondaryBorder};
            stroke-width: 2px;
          }
          circle {
            fill: ${theme.color.body};
            stroke: ${theme.color.secondaryText};
            stroke-width: 2px;
          }
          path {
            stroke-width: 6px;
            fill: none;
          }
          rect {
            fill: ${theme.color.secondaryBg};
          }
          .handle {
            cursor: grab;
            circle {
              fill: ${theme.color.primaryText};
              stroke: none;
            }
          }
        }
      `}
    >
      <svg
        viewBox={`0 0 ${size + padHorz * 2 + 2}px ${size + padVert * 2 + 2}px`}
        width={`${size + padHorz * 2 + 2}`}
        height={`${size + padVert * 2 + 2}`}
        xmlns="http://www.w3.org/2000/svg"
      >
        <defs>
          <linearGradient id="myGradient" gradientTransform="rotate(90)">
            <stop offset="5%" stopColor="#50fa7b" />
            <stop offset="95%" stopColor="#8be9fd" />
          </linearGradient>
        </defs>
        {/* The grid lines */}
        <rect x={padHorz} y={padVert} width={size} height={size} />
        <line
          className="gridLine"
          x1={padHorz}
          y1={0}
          x2={padHorz}
          y2={size + padVert * 2}
        />

        <line
          className="gridLine"
          x1={size + padHorz}
          y1={0}
          x2={size + padHorz}
          y2={size + padVert * 2}
        />
        {/* <line
          className="gridLine"
          y1={padVert}
          x1={padHorz}
          y2={padVert}
          x2={size + padHorz}
        />

        <line
          className="gridLine"
          y1={size + padVert}
          x1={padHorz}
          y2={size + padVert}
          x2={size + padHorz}
        /> */}
        {/* The lines between the points */}
        <line
          className="lineToPoint"
          x1={padHorz}
          y1={size + padVert}
          x2={padHorz + p1x * size}
          y2={padVert + (1 - p1y) * size}
        />
        <line
          className="lineToPoint"
          x1={padHorz + size}
          y1={padVert}
          x2={padHorz + p2x * size}
          y2={padVert + (1 - p2y) * size}
        />
        <path
          stroke="url(#myGradient)"
          d={`M ${padHorz} ${padVert + size} C ${padHorz +
            p1x * size} ${padVert + (1 - p1y) * size}, ${padHorz +
            p2x * size} ${padVert + (1 - p2y) * size}, ${padHorz +
            size} ${padVert}`}
        />
        <Point
          onChange={(newValue) => {
            setCurve({
              ...curve,
              p1x: newValue[0],
              p1y: newValue[1],
            });
          }}
          curve={[p1x, p1y]}
          size={size}
        >
          <circle
            cx={padHorz + p1x * size}
            cy={padVert + (1 - p1y) * size}
            r={c}
          />
        </Point>
        <Point
          onChange={(newValue) => {
            setCurve({
              ...curve,
              p2x: newValue[0],
              p2y: newValue[1],
            });
          }}
          curve={[p2x, p2y]}
          size={size}
        >
          <circle
            cx={padHorz + p2x * size}
            cy={padVert + (1 - p2y) * size}
            r={c}
          />
        </Point>

        <circle cx={padHorz} cy={padVert + size} r={7} />
        <circle cx={padHorz + size} cy={padVert} r={7} />
      </svg>
    </div>
  );
};

const PresetCurve = ({ name, value }) => {
  const theme = useTheme();
  const size = 80;
  const padHorz = 5;
  const padVert = 20;
  const c = 3;
  const [p1x, p1y, p2x, p2y] = value.split(',');

  return (
    <div
      className={css`
        width: 8rem;
        display: flex;
        align-items: center;
        flex-direction: column;
        border: 1px solid ${theme.color.secondaryBorder};
        margin-bottom: 1rem;
        margin-right: 1rem;
        text-align: center;
        padding: 0.5rem;
      `}
    >
      <div
        className={css`
          svg {
            .lineToPoint {
              stroke: ${theme.color.secondaryBorder};
              stroke-width: 2px;
            }
            circle {
              fill: ${theme.color.secondaryText};
              stroke: none;
            }
            path {
              stroke: ${theme.color.border};
              stroke-width: 2px;
              fill: none;
            }
            rect {
              fill: ${theme.color.secondaryBg};
            }
          }
        `}
      >
        <svg
          viewBox={`0 0 ${size + padHorz * 2 + 2}px ${size +
            padVert * 2 +
            2}px`}
          width={`${size + padHorz * 2 + 2}`}
          height={`${size + padVert * 2 + 2}`}
          xmlns="http://www.w3.org/2000/svg"
        >
          {/* The grid lines */}
          <rect x={padHorz} y={padVert} width={size} height={size} />

          {/* The lines between the points */}
          <line
            className="lineToPoint"
            x1={padHorz}
            y1={size + padVert}
            x2={padHorz + p1x * size}
            y2={padVert + (1 - p1y) * size}
          />
          <line
            className="lineToPoint"
            x1={padHorz + size}
            y1={padVert}
            x2={padHorz + p2x * size}
            y2={padVert + (1 - p2y) * size}
          />
          <path
            d={`M ${padHorz} ${padVert + size} C ${padHorz +
              p1x * size} ${padVert + (1 - p1y) * size}, ${padHorz +
              p2x * size} ${padVert + (1 - p2y) * size}, ${padHorz +
              size} ${padVert}`}
          />

          <circle
            cx={padHorz + p1x * size}
            cy={padVert + (1 - p1y) * size}
            r={c}
          />
          <circle
            cx={padHorz + p2x * size}
            cy={padVert + (1 - p2y) * size}
            r={c}
          />

          <circle cx={padHorz} cy={padVert + size} r={c} />
          <circle cx={padHorz + size} cy={padVert} r={c} />
        </svg>
      </div>
      <small>{name}</small>
    </div>
  );
};

function App({ isDark, setIsDark }) {
  const theme = useTheme();
  const presets = {
    'In sine': '0.47, 0, 0.745, 0.715',
    'Out sine': '0.39, 0.575, 0.565, 1',
    'In out sine': '0.445, 0.05, 0.55, 0.95',
    'In quad': '0.55, 0.085, 0.68, 0.53',
    'Out quad': '0.25, 0.46, 0.45, 0.94',
    'In out quad': '0.455, 0.03, 0.515, 0.955',
    'In cubic': '0.55, 0.055, 0.675, 0.19',
    'Out cubic': '0.215, 0.61, 0.355, 1',
    'In out cubic': '0.645, 0.045, 0.355, 1',
    'In quart': '0.895, 0.03, 0.685, 0.22',
    'Out quart': '0.165, 0.84, 0.44, 1',
    'In out quart': '0.77, 0, 0.175, 1',
    'In quint': '0.755, 0.05, 0.855, 0.06',
    'Out quint': '0.23, 1, 0.32, 1',
    'In out quint': '0.86, 0, 0.07, 1',
    'In expo': '0.95, 0.05, 0.795, 0.035',
    'Out expo': '0.19, 1, 0.22, 1',
    'In out expo': '1, 0, 0, 1',
    'In circ': '0.6, 0.04, 0.98, 0.335',
    'Out circ': '0.075, 0.82, 0.165, 1',
    'In out circ': '0.785, 0.135, 0.15, 0.86',
    'In back': '0.6, -0.28, 0.735, 0.045',
    'Out back': '0.175, 0.885, 0.32, 1.275',
    'In out back': '0.68, -0.55, 0.265, 1.55',
  };
  const [curve, setCurve] = useState({
    p1x: 0.5,
    p1y: 0.2,
    p2x: 0.5,
    p2y: 1.2,
  });
  const { p1x, p1y, p2x, p2y } = curve;
  return (
    <div>
      <header
        className={css`
          height: ${theme.headerHeight};
          border-bottom: 1px solid ${theme.color.border};
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding: 0 2rem;
        `}
      >
        <h2>Cubic Bezier</h2>
        <div
          className={css`
            display: flex;
          `}
        >
          <span>cubic-bezier(</span>
          <div
            className={css`
              display: flex;
              input {
                padding: 0;
                border: 0;
                width: 3em;
              }
            `}
          >
            <span>{Math.round(p1x * 100) / 100}</span>,
            <span>{Math.round(p1y * 100) / 100}</span>,
            <span>{Math.round(p2x * 100) / 100}</span>,
            <span>{Math.round(p2y * 100) / 100}</span>
          </div>
          <span>)</span>
        </div>
        <button type="button" onClick={() => setIsDark(!isDark)}>
          {isDark ? 'dark' : 'light'}
        </button>
      </header>
      <main
        className={css`
          display: grid;
          grid-template-columns: 1fr 2fr;
        `}
      >
        <div
          className={css`
            height: calc(100vh - ${theme.headerHeight});
            border-right: 1px solid ${theme.color.border};
            display: flex;
            align-items: center;
            justify-content: center;
            overflow-y: scroll;
          `}
        >
          <EasingCurve curve={curve} setCurve={setCurve} />
        </div>
        <div
          className={css`
            padding: 1.5rem;
          `}
        >
          <div>
            <div>
              <h2>Preview</h2>

              <div>
                <div
                  className={css`
                    width: 4rem;
                    height: 4rem;
                    border-radius: 0.1em;
                    border: 2px solid ${theme.color.secondaryText};
                    transform: translate3d(0, 0, 0);
                    animation: ${demoSlide} 0.75s
                      cubic-bezier(${p1x}, ${p1y}, ${p2x}, ${p2y}) infinite
                      alternate;
                  `}
                />
              </div>
              <div
                className={css`
                  padding-top: 0.5rem;
                `}
              >
                <div
                  className={css`
                    width: 4rem;
                    height: 4rem;
                    border-radius: 0.1em;
                    border: 2px solid ${theme.color.secondaryText};
                    transform: translate3d(0, 0, 0);
                    animation: ${demoSlide} 0.75s linear infinite alternate;
                  `}
                />
              </div>
            </div>
            <div>
              <h2>Presets</h2>
              <ul
                className={css`
                  display: flex;
                  flex-wrap: wrap;
                `}
              >
                {Object.entries(presets).map(([key, value]) => (
                  <li
                    key={key}
                    onClick={() => {
                      const values = value.split(',');
                      setCurve({
                        p1x: parseFloat(values[0]),
                        p1y: parseFloat(values[1]),
                        p2x: parseFloat(values[2]),
                        p2y: parseFloat(values[3]),
                      });
                    }}
                  >
                    <PresetCurve name={key} value={value} />
                  </li>
                ))}
              </ul>
            </div>
          </div>
          <footer
            className={css`
              position: fixed;
              bottom: 0;
              background: ${theme.color.body};
              padding: 1.5rem;
              font-size: 0.8em;
            `}
          >
            Building on the{' '}
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://cubic-bezier.com/#.67,-1.85,.68,1.87"
            >
              concept
            </a>{' '}
            by Lea Verou. Created by{' '}
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://github.com/Reklino"
            >
              a fan
            </a>
          </footer>
        </div>
      </main>
    </div>
  );
}

export default App;
