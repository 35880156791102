import React, { useState } from 'react';
import ReactDOM from 'react-dom';
import { Global, css } from '@emotion/core';
import { ThemeProvider } from 'emotion-theming';
import reset from 'reset-css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import './fonts/Suisse/Suisse/stylesheet.css';

const rootTheme = (isDark) => {
  return {
    baseFontSize: 20,
    typeScale: 2.15,
    headerHeight: '4rem',
    color: isDark
      ? {
          body: '#21222C',
          secondaryBg: '#282a36',
          border: '#f8f8f2',
          secondaryBorder: '#44475a',
          primaryText: '#f8f8f2',
          secondaryText: '#bd93f9',
        }
      : {
          body: '#ffffff',
          secondaryBg: '#f8f8f2',
          border: '#282a36',
          secondaryBorder: '#44475a',
          primaryText: '#282a36',
          secondaryText: '#ff79c6',
        },
  };
};

const Root = () => {
  const [isDark, setIsDark] = useState(
    window.matchMedia('(prefers-color-scheme: dark)').matches,
  );
  const theme = rootTheme(isDark);
  return (
    <ThemeProvider theme={theme}>
      <Global
        styles={css`
          ${reset}

          html, body, h1, h2, h3, h4, h5, p, span, input, button {
            font-family: 'suisse-intl-mono', monospace, -apple-system,
              BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
              'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
              sans-serif;
            -webkit-font-smoothing: antialiased;
            -moz-osx-font-smoothing: grayscale;
            color: ${theme.color.primaryText};
          }

          html,
          body {
            background: ${theme.color.body};
            color: ${theme.color.primaryText};
          }
          button,
          input {
            border: 1px solid ${theme.color.border};
            background: ${theme.color.body};
            color: ${theme.color.primaryText};
          }

          small {
            font-size: 0.8em;
          }
        `}
      />
      <App isDark={isDark} setIsDark={setIsDark} />
    </ThemeProvider>
  );
};

ReactDOM.render(
  <React.StrictMode>
    <Root />
  </React.StrictMode>,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
